import React from 'react';
import PropTypes from 'prop-types';
import { IconLiquids, Text } from '@freska/freska-ui';
import { FormattedMessage } from 'react-intl';

import DataBlock from '../Common/DataBlock';

const propTypes = {
  cleaningSuppliesByFreska: PropTypes.bool.isRequired,
};

const BookingDetailsCleaningSupplies = ({ cleaningSuppliesByFreska }) => (
  <DataBlock Icon={IconLiquids} title="bookings.card.cleaning_supplies">
    <Text m={0} as="p">
      <FormattedMessage
        id={`bookings.details.cleaning_supplies.${
          cleaningSuppliesByFreska ? 'by_freska' : 'by_customer'
        }`}
      />
    </Text>
  </DataBlock>
);

BookingDetailsCleaningSupplies.propTypes = propTypes;

export default BookingDetailsCleaningSupplies;
